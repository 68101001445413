import * as Envalid from 'envalid';

/*
|==========================================================================
| Configuration
|==========================================================================
|
| Environment variables are used to configure the application, this is a mapping
| of those variables to a configuration object.
|
*/

const env = Envalid.cleanEnv(import.meta.env, {
  VITE_ENVIRONMENT: Envalid.str({
    choices: ['local', 'test', 'previews', 'staging', 'production'],
  }),
  VITE_RELEASE_VERSION: Envalid.str(),
  VITE_LOG_TYPE: Envalid.str({
    default: 'http',
    choices: ['http', 'console', 'none'],
  }),
  VITE_LOG_LEVEL: Envalid.str({
    choices: ['trace', 'debug', 'info', 'warn', 'error', 'fatal'],
  }),
  VITE_POSTHOG_API_KEY: Envalid.str(),
  VITE_POSTHOG_INSTANCE: Envalid.str({
    default: `${window.location.origin}/api/prx/ph`,
  }),
  VITE_FRIGADE_API_KEY: Envalid.str(),
  VITE_FRIGADE_URL: Envalid.str({
    default: `${window.location.origin}/api/prx/fg`,
  }),
  VITE_API_URL: Envalid.str(),
  VITE_AUTH0_DOMAIN: Envalid.str(),
  VITE_AUTH0_CLIENT_ID: Envalid.str(),
  VITE_AUTH0_AUDIENCE: Envalid.str(),
  VITE_GITHUB_APP_CLIENT_ID: Envalid.str(),
  VITE_GITHUB_APP_INSTALL_URL: Envalid.str(),
  VITE_SENTRY_ENABLED: Envalid.bool(),
  VITE_SENTRY_DSN: Envalid.str(),
  VITE_SENTRY_PROFILE_SAMPLE_RATE: Envalid.num(),
  VITE_SENTRY_TRACE_SAMPLE_RATE: Envalid.num(),
  VITE_SENTRY_REPLAYS_SESSION_SAMPLE_RATE: Envalid.num(),
  VITE_SENTRY_REPLAYS_ERROR_SAMPLE_RATE: Envalid.num(),
  VITE_TIPTAP_AI_APP_ID: Envalid.str(),
  VITE_CDN_URL: Envalid.str(),
});

const extractSentryProjectId = (dsn: string): number => {
  const parts = dsn.split('/');
  const projectId = parts[parts.length - 1];

  const projectIdNum = Number(projectId);

  if (Number.isNaN(projectIdNum)) {
    throw new Error(`Invalid project ID: ${projectId}`);
  }

  return projectIdNum;
};

/*
|------------------
| Configuration
|------------------
*/

const config = {
  logging: {
    type: env.VITE_LOG_TYPE,
    level: env.VITE_LOG_LEVEL,
  },
  releaseVersion: env.VITE_RELEASE_VERSION,
  environment: env.VITE_ENVIRONMENT,
  posthog: {
    apiKey: env.VITE_POSTHOG_API_KEY,
    instance: env.VITE_POSTHOG_INSTANCE,
  },
  frigade: {
    apiKey: env.VITE_FRIGADE_API_KEY,
    apiUrl: env.VITE_FRIGADE_URL,
  },
  api: {
    url: env.VITE_API_URL,
  },
  auth0: {
    url: `https://${env.VITE_AUTH0_DOMAIN}`,
    clientId: env.VITE_AUTH0_CLIENT_ID,
    audience: env.VITE_AUTH0_AUDIENCE,
  },
  githubApp: {
    clientId: env.VITE_GITHUB_APP_CLIENT_ID,
    installUrl: env.VITE_GITHUB_APP_INSTALL_URL,
  },
  tiptap: {
    ai: {
      appId: env.VITE_TIPTAP_AI_APP_ID,
    },
  },
  sentry: {
    org: 'joggr',
    enabled: env.VITE_SENTRY_ENABLED,
    dsn: env.VITE_SENTRY_DSN,
    projectId: extractSentryProjectId(env.VITE_SENTRY_DSN),
    profileSampleRate: env.VITE_SENTRY_PROFILE_SAMPLE_RATE,
    traceSampleRate: env.VITE_SENTRY_TRACE_SAMPLE_RATE,
    replaysSessionSampleRate: env.VITE_SENTRY_REPLAYS_SESSION_SAMPLE_RATE,
    replaysErrorSampleRate: env.VITE_SENTRY_REPLAYS_ERROR_SAMPLE_RATE,
  },
  cdn: {
    url: env.VITE_CDN_URL,
  },
  url: window.location.origin,
};

export type Config = typeof config;

export default config;
